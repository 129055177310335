import React from 'react';
import { graphql } from 'gatsby';
import '../fragments';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { RawPortableText } from '../types';
import { LocalizedSEO } from '../fragments';
import LayoutContainer from '../components/LayoutContainer';
import PageSEO from '../components/PageSEO';
import styled from 'styled-components';
import { Link } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import serializers from '../serializers';
import media from 'css-in-js-media';

export const query = graphql`
  query GetSpotlightById($id: String!) {
    sanitySpotlight(id: { eq: $id }) {
      title
      position
      linkedin
      slug {
        current
      }
      sector {
        title
        slug {
          current
        }
      }
      image {
        asset {
          gatsbyImageData(width: 1110, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          url
        }
      }
      _rawBody(resolveReferences: { maxDepth: 4 })
      seo {
        ...SEO
      }
    }
  }
`;

interface SpotlightPageProps {
  data: {
    sanitySpotlight: {
      title: string;
      position: string;
      linkedin: string;
      slug: {
        current: string;
      };
      sector: {
        title: string;
        slug: {
          current: string;
        };
      };
      image: {
        asset: {
          gatsbyImageData: IGatsbyImageData;
          url: string;
        };
      };
      _rawBody: RawPortableText;
      seo: LocalizedSEO;
    };
  };
}

const PageRoot = styled.div`
  position: relative;
  width: 100%;
  padding: 158px 0px;
`;

const BreadcrumbsContainer = styled.div`
  background-color: var(--colour-light-grey);
  padding: 20px 32px;
  width: 100%;
`;

const Breadcrumbs = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0px;
  margin: 0px;
`;

const BreadcrumbsItem = styled.li`
  margin: 0px;
`;

const BreadcrumbsLink = styled(Link)`
  color: #000;
  text-align: center;
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  text-decoration-line: underline;

  svg {
    margin: 0px 10px;
  }
`;

const BreadcrumbsCurrent = styled.span`
  color: #000;
  text-align: center;
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1110px;
  margin: 0px auto;
  padding: 0px 32px;
`;

const SpotlightContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 55px 0px;

  ${media('>tablet')} {
    grid-template-columns: 1fr 2fr;
    grid-gap: 75px 18px;
  }
`;

const SpotlightImage = styled.div`
  grid-column-start: 1;
  aspect-ratio: 3 / 2.5;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media('>tablet')} {
    grid-column-start: 2;
    aspect-ratio: 3 / 2;
  }
`;

const SpotlightBackButton = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--colour-black);
  text-align: center;
  font-family: var(--light-body-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-decoration: none;
  margin: 25px 0px;

  svg {
    margin-right: 10px;
  }

  ${media('>tablet')} {
    margin: 50px 0px;
  }
`;

const SpotlightTitle = styled.h1`
  color: var(--colour-black);
  font-family: var(--light-body-font-family);
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;

  strong {
    display: block;
    font-weight: 500;
  }

  ${media('>tablet')} {
    font-size: 32px;
    line-height: 40px;
  }
`;

const SpotlightText = styled(BlockContent)`
  color: var(--colour-black);
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: -30px;

  ${media('>tablet')} {
    margin-top: 0px;
  }
`;

const LinkedInButton = styled.a`
  display: flex;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--colour-black);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: -0.5px;  
  border-radius: 6px;
  background: var(--colour-turquoise);
  width: fit-content;
  margin-top: 30px;
  cursor: pointer;
  text-decoration: none;
  
  &:hover {
    color: var(--colour-black);
  }
`;

const SpotlightPage = ({ data }: SpotlightPageProps): React.ReactElement => {
  const { sanitySpotlight: spotlight } = data;

  return (
    <LayoutContainer
      pageTitle={`${spotlight.title} | Spotlights`}
      headerBackground={'black'}
      showLetsTalkBanner={true}
    >
      <PageSEO
        pageSEO={spotlight.seo}
        defaultTitle={spotlight.title}
        defaultImageUrl={spotlight.image?.asset?.url}
      />
      <PageRoot>
        <BreadcrumbsContainer>
          <Breadcrumbs>
            <BreadcrumbsItem>
              <BreadcrumbsLink to="/">
                Home
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="11"
                  viewBox="0 0 8 11"
                  fill="none"
                >
                  <path
                    d="M2 9.5L6 5.5L2 1.5"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                </svg>
              </BreadcrumbsLink>
            </BreadcrumbsItem>
            <BreadcrumbsItem>
              <BreadcrumbsLink to={`/sectors/${spotlight.sector?.slug?.current}`}>
                {spotlight.sector?.title}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="11"
                  viewBox="0 0 8 11"
                  fill="none"
                >
                  <path
                    d="M2 9.5L6 5.5L2 1.5"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                </svg>
              </BreadcrumbsLink>
            </BreadcrumbsItem>
            <BreadcrumbsItem>
              <BreadcrumbsCurrent>Spotlight: {spotlight.title}</BreadcrumbsCurrent>
            </BreadcrumbsItem>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <ContentContainer>
          <SpotlightBackButton to={`/sectors/${spotlight.sector?.slug?.current}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="14"
              viewBox="0 0 13 14"
              fill="none"
            >
              <path d="M8 1L2 6.81818L8 13" stroke="currentColor" stroke-width="1.5" />
              <path d="M13 7H2" stroke="currentColor" stroke-width="1.5" />
            </svg>
            {spotlight.sector?.title}
          </SpotlightBackButton>
          <SpotlightContent>
            <SpotlightImage>
              <img src={spotlight.image?.asset?.url} alt={spotlight.title} />
            </SpotlightImage>
            <SpotlightTitle>
              <strong>Spotlight:</strong>
              {spotlight.title} - {spotlight.position}
            </SpotlightTitle>
            <div>
              <SpotlightText blocks={spotlight._rawBody} serializers={serializers} renderContainerOnSingleChild />
              <LinkedInButton href={spotlight.linkedin} target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <circle cx="8" cy="8" r="8" fill="#000F14"/>
                  <path d="M5.81609 12V6.60207H4.10115V12H5.81609ZM4.95892 5.86521C5.55684 5.86521 5.92908 5.45068 5.92908 4.93268C5.91784 4.40292 5.55684 4 4.97031 4C4.38333 4 4 4.40292 4 4.93268C4 5.45068 4.37209 5.86521 4.94768 5.86521H4.95892ZM6.76527 12H8.48006V8.98591C8.48006 8.82474 8.4913 8.66326 8.53655 8.54818C8.66048 8.22568 8.94266 7.8919 9.4165 7.8919C10.0369 7.8919 10.2852 8.38685 10.2852 9.11259V12H12V8.90501C12 7.24707 11.1541 6.47555 10.0258 6.47555C9.10076 6.47555 8.6945 7.01644 8.46882 7.38487H8.48021V6.60223H6.76542C6.78775 7.10863 6.76527 12 6.76527 12Z" fill="#1ACEBC"/>
                </svg>                
                Follow {spotlight.title} on LinkedIn
              </LinkedInButton>
            </div>
          </SpotlightContent>
        </ContentContainer>
      </PageRoot>
    </LayoutContainer>
  );
};

export default SpotlightPage;
